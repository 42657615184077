
export const constants = () => {
  return {
    PATENT_FOR_STARTUPS: 'Patent For Startups',
    ALL_ABOUT_PATENTS: 'All About Patents',
    TRADEMARK: 'Trademark - Protecting Your Brands',
    UNCATEGORIED: 'Uncategorized',
    carouselResponsive: {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1
      }
    }
  }
}

export const addSubcriber = async (data) => {
  const response  = await fetch("https://api.trustyip.com/subcribers/add", {
    "method": "POST",
    "headers": {
        "content-type": "application/json",
        "accept": "application/json"
    },
    "body": JSON.stringify(data)
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  
    const result = await response.json();
    return result;
}

export const todayStr = () => {
  var date = new Date();
  return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
}

export const isValidEmail = (email) =>{
  return /\S+@\S+\.\S+/.test(email);
}

export const addAndRemoveDisabledCls = (eleId) => {
  const ele = document?.getElementById(eleId);
  if (ele) {
    ele.classList.add("disabled");
    // Remove after 3 minutes
    setTimeout(function() {
      const ele = document?.getElementById(eleId);
      if (ele) {
        ele.classList.remove("disabled");
      }
    }, 3 * 60 * 1000);
  }
}

export const validateSubscriberData = (person) => {
  if ('firstName' in person) {
    if (person.firstName.trim() === '') {
      alert('First name is invalid!!!');
      return false;
    }
  }

  //
  if ('lastName' in person) {
    if (person.lastName.trim() === '') {
      alert('Last name is invalid!!!');
      return false;
    }
  }

  //
  if ('name' in person) {
    if (person.name.trim() === '') {
      alert('Name is invalid!!!');
      return false;
    }
  }

  //
  if ('email' in person) {
    if (!isValidEmail(person.email)) {
      alert('Email is invalid!!!');
      return false;
    }
  }

  // The number field has to have 8+ digits
  if ('phone' in person) {
    const phone_trim = person.phone.trim();
    const regex = /[a-zA-Z\s]/g;
    const found = phone_trim.match(regex);
    if ((phone_trim.length < 8) || (found)) {
      alert('The number field has to have 8+ digits');
      return false;
    }
  }

  //
  if ('subject' in person) {
    if (person.subject.trim() === '') {
      alert('Subject is invalid!!!');
      return false;
    }
  }

  // The message has to be 20+ characters.
  if ('message' in person) {
    const mess = person.message.trim().replace(/(\r\n|\n|\r)/gm,"");
    if (mess.length < 20) {
      alert('The message has to be 20+ characters');
      return false;
    }
  }

  return true;
}

export const isSSR = () => typeof window === 'undefined' || !window.document;

export const startWithHeaderTag = (content) => {
  // check server side rendering
  // https://github.com/gatsbyjs/gatsby/issues/19487
  if (isSSR()) {
    return;
  }
  // create blog content obj
  var contentObj = document.createElement( 'div' );
  contentObj.innerHTML = content;
  // get first child
  var firstChild = contentObj.firstChild;
  while(firstChild != null && firstChild.nodeType == 3){ // skip TextNodes
    firstChild = firstChild.nextSibling;
  }
  const tagName = firstChild.tagName;
  const headerTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  if (headerTags.includes(tagName.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}

/**
 * content
 * 
 * @return content
 */
export const removeHtmltagsInsideH2H3Tags = (content) => {
  // check server side rendering
  // https://github.com/gatsbyjs/gatsby/issues/19487
  if (isSSR()) {
    return;
  }
  // create blog content obj
  var contentObj = document.createElement( 'div' );
  contentObj.innerHTML = content;

  // update h2-h3 content text
  var h2Tags = contentObj.getElementsByTagName('h2');
  for(const h2 of h2Tags) {
    h2.innerHTML = h2.innerText || h2.textContent;
  }

  var h3Tags = contentObj.getElementsByTagName('h3');
  for(const h3 of h3Tags) {
    h3.innerHTML = h3.innerText || h3.textContent;
  }

  return contentObj.innerHTML;
}

export const getFirstTextNode = (content) => {
    // check server side rendering
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (isSSR()) {
      return;
    }
    // create blog content obj
    var contentObj = document.createElement( 'div' );
    contentObj.innerHTML = content;

    var firstText = "Debug";
    // get all p href
    var pTags = contentObj.getElementsByTagName('p');
    for (const p of pTags) {
      firstText = p.innerText || p.textContent || '';
      if (firstText.trim().length > 100) {
        break;
      }
    }
    // console.log(`first text: `, firstText)
    return firstText;
}

export const updatePageContent = (content) => {
    // check server side rendering
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (isSSR()) {
      return;
    }
    // Remove part
    const WP_URL = '';
    // create blog content obj
    var contentObj = document.createElement( 'div' );
    contentObj.innerHTML = content;

    // get all a href
    var aTags = contentObj.getElementsByTagName('a');
    for (const a of aTags) {
      a.setAttribute("href", a.href.replace(/^.*\/\/[^\/]+/, '/blog'));
    }

    /**
     * Call To Action Content
     */
    const actionBoxHtml = `
    <div class="row mb-4">
      <div class="col-md-2 pt-3 pb-3" style="background-color: #EEEEEE">
        <img src="https://wp.patentpc.com/wp-content/uploads/2022/01/bao-2.png" class="h-100"/>
      </div>
      <div class="col-md-4 pt-3 pb-3" style="background-color: #EEEEEE">
        See How INVENTIV can help grow your company valuation and protect your
        business with our expert lawyers and their advanced AI workflow.
        <p class="mt-5"><a rel="noreferrer" target="_blank" href="https://calendly.com/powerpatent/powerpatent-meeting" class="btn btn-warning" style="width: 90%;">Request A Meeting</a></p>
      </div>
    </div>
    `;

    const actionBoxObj = document.createElement( 'div' );
    actionBoxObj.innerHTML = actionBoxHtml;

    var pTags = contentObj.getElementsByTagName('p');
    // Insert before the last p tag
    if (pTags && pTags.length) {
      const lastP = pTags[pTags.length - 1];
      const parentDiv = lastP.parentNode;
      parentDiv.insertBefore(actionBoxObj, lastP);
    }

    // return 
    return contentObj.innerHTML
}

export const getSubTitle = (content) => {
    // check server side rendering
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (isSSR()) {
      return;
    }

    // create blog content obj
    var contentObj = document.createElement( 'div' );
    contentObj.innerHTML = content;

    // get first h2 for sub title
    var value = '';
    var abposts = contentObj.getElementsByClassName('abpost-category');
    if( abposts && abposts.length) {
      const h2 = abposts[0];
      // get value
      value = h2.innerText;
    }

    return value;
};

export const updateBlogContent = (content, humanPosts = null) => {
    // check server side rendering
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (isSSR()) {
      return;
    }

    var humanPostContent = '';
    if (humanPosts && humanPosts.length) {
      humanPostContent = '<ul>';
      humanPosts.forEach(p => {
        humanPostContent += `<li>
          <a href="/blog/${p.slug}">${p.title}</a>
        </li>`
      })
      humanPostContent += '</ul>';
    }
    /**
     * Disclaimer content
     */
    const disclaimerHtml = `
      <div>
      <a href="/patentfaqs/" rel="noreferrer" target="_blank">Patent FAQs</a> - <a href="/tmfaqs/" rel="noreferrer" target="_blank">Trademark FAQs</a>
      </div>
      <p class="h6 mt-2">Check out some  our latest posts on patent filling and patent news:</p>
      ${humanPostContent}
      <hr>
      <p class="disclaimer-text fw-normal">Disclaimer:</p>
      <p class="disclaimer-text fw-normal">
      The information provided on this blog does not, and is not intended to, constitute legal advice; instead, all information, 
      content, and materials available on this site are for general informational purposes only. Information on this website may not 
      constitute the most up-to-date legal or other information. This website contains links to other third-party websites. 
      Such links are only for the reader, user or browser; we do not recommend or endorse the contents of the third-party sites.
      </p>
      <p class="disclaimer-text fw-normal">
      Readers of this website should contact their attorney to obtain advice for any particular legal matter. 
      No reader, user, or browser of this site should act or refrain from acting based on information on this site 
      without first seeking legal advice from counsel in the relevant jurisdiction. Only your attorney can provide 
      assurances that the information contained herein – and your interpretation of it – is applicable or appropriate 
      to your particular situation. Use of and access to this website or any links or resources within this site do 
      not create an attorney-client relationship between the reader, user, or browser and website authors, contributors, 
      contributing law firms, and their respective employers.
      </p>
      <p class="disclaimer-text fw-normal">
      The views expressed at or through this site are those of the authors writing in their individual capacities only – not this site. 
      All liability for actions taken or not taken based on the contents of this site are expressly disclaimed. 
      The content on this posting is provided “as is;” no representations are made that the content is error-free.
      </p>
    `;

    const disclaimerObj = document.createElement( 'div' );
    disclaimerObj.innerHTML = disclaimerHtml;

    /**
     * Call To Action Content
     */
    const actionBoxHtml = `
    <div class="row mb-5">
      <div class="col-md-3 pt-3 pb-3" style="background-color: #EEEEEE">
        <img src="https://wp.patentpc.com/wp-content/uploads/2022/01/bao-2.png" class="h-100"/>
      </div>
      <div class="col-md-6 pt-3 pb-3" style="background-color: #EEEEEE">
        See How INVENTIV can help grow your company valuation and protect your
        business with our expert lawyers and their advanced AI workflow.
        <p class="mt-5"><a rel="noreferrer" target="_blank" href="https://calendly.com/powerpatent/powerpatent-meeting" class="btn btn-warning" style="width: 90%;">Request A Meeting</a></p>
      </div>
    </div>
    `;

    const actionBoxObj = document.createElement( 'div' );
    actionBoxObj.innerHTML = actionBoxHtml;

    // create blog content obj
    var contentObj = document.createElement( 'div' );
    contentObj.innerHTML = content;
    
    // Do not remove this tag, but set hidden
    var abposts = contentObj.getElementsByClassName('abpost-category');
    for (const a of abposts) {
      a.style.display = "none"
    }

    // insert call to action box
    var hasDivCTA = false;
    var divCTAs = contentObj.getElementsByClassName('contact-us-holder');
    for (const divCTA of divCTAs) {
      divCTA.innerHTML = actionBoxHtml;
      hasDivCTA = true;
    }

    // update h2-h3 content text
    var h2Tags = contentObj.getElementsByTagName('h2');
    for(const h2 of h2Tags) {
      h2.innerHTML = h2.innerText || h2.textContent;
    }

    var h3Tags = contentObj.getElementsByTagName('h3');
    for(const h3 of h3Tags) {
      h3.innerHTML = h3.innerText || h3.textContent;
    }

    /**
     * Center img
     */
    var pTags = contentObj.getElementsByTagName('p');
    // for (const p of pTags) {
    //   if( p.getElementsByTagName('img').length > 0) {
    //     // there is an image
    //     p.classList.add("text-center");
    //     // break;
    //   }
    // }

    // No DIV CTA Tag
    // Insert before the last p tag
    if (!hasDivCTA && pTags && pTags.length) {
      const lastP = pTags[pTags.length - 1];
      const parentDiv = lastP.parentNode;
      parentDiv.insertBefore(actionBoxObj, lastP);
    }
    
    /**
     * Add Disclaimer
     */
    contentObj.appendChild(disclaimerObj);

    /**
     * 
     */
    const gatsbyImgObjs = contentObj.getElementsByClassName('gatsby-image-wrapper');
    for (const gatsbyImgObj of  gatsbyImgObjs) {
      // get img src set
      const imgs = gatsbyImgObj.getElementsByTagName('img');
      const imgValue = (imgs && imgs.length > 1 ? imgs[1].dataset.src : null);

      // assign img
      if (imgValue) {
        imgs[0].src = imgValue;
      }
      // set first div to 100% max-width
      const subDivs = gatsbyImgObj.getElementsByTagName('div');
      if (subDivs && subDivs.length) {
        const firstDiv = subDivs[0];
        if(firstDiv.getAttribute("style")){
          if(firstDiv.getAttribute("style").indexOf("max-width:") != -1){
              firstDiv.style.maxWidth = "100%";  
          }
        }
      }

      // add text-muted to figcaption
      const figcaptions = contentObj.getElementsByTagName('figcaption');
      for (const figcaption of  figcaptions) {
        figcaption.classList.add("text-muted", "small");
      }
    }
 
    //
    return contentObj.innerHTML;
}